import { QueryClientConfig } from '@tanstack/react-query';

export const REACT_QUERY_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      suspense: true,
      useErrorBoundary: true,
      retry: false,
    },
  },
};
