import Link from 'next/link';
import { FC, useRef, useState } from 'react';
import ExpandMoreBoldIcon from 'src/app.core/linkareer/svgIcon/ExpandMoreBoldIcon';
import styled from 'styled-components';
import NewBadge from '../component/NewBadge';
import { useNavTabScrollIntoView } from '../hook/useNavTabScrollIntoView';
import {
  MOBILE_MENU_ITEMS,
  NavBarActiveKey,
  NavBarBadge,
} from 'src/app.core/linkareer/constant/navBarMenuItems';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { useActiveCategory } from '../../hook/useActiveCategory';

const NavBarMobile: FC = () => {
  const [expandMenuVisible, setExpandMenuVisible] = useState(false);
  const activeCategory = useActiveCategory();
  const ref = useRef<HTMLAnchorElement[] | null[]>([]);
  const secondDepthMenuDataList = MOBILE_MENU_ITEMS.find(
    (item) => item.active === NavBarActiveKey.CBT
  );

  const handleExpandBtnClick = () => {
    if (!expandMenuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    setExpandMenuVisible((prev) => !prev);
  };

  useNavTabScrollIntoView({
    targetTab: NavBarActiveKey.CBT,
    navItems: MOBILE_MENU_ITEMS,
    parentRef: ref,
  });

  return (
    <StyledWrapper>
      <div className="menu-list">
        {MOBILE_MENU_ITEMS.map((menuItem, index) => (
          <Link
            key={menuItem.label}
            href={menuItem.href}
            className="menu-item"
            data-active={menuItem.active === NavBarActiveKey.CBT}
            data-badge={menuItem.badge === NavBarBadge.NEW}
            ref={(el) => (ref.current[index] = el)}
          >
            {menuItem.label}
            <div className="active-line" />
            {menuItem.badge === NavBarBadge.NEW && <NewBadge />}
          </Link>
        ))}
        <div className="gradient-box" />
        <div className="expand-more-btn" onClick={handleExpandBtnClick}>
          <ExpandMoreBoldIcon />
        </div>
      </div>
      {expandMenuVisible && (
        <div className="expand-wrapper">
          <div className="expand-menu-list">
            {MOBILE_MENU_ITEMS.map((menuItem) => (
              <Link
                key={menuItem.label}
                href={menuItem.href}
                className="expand-menu-item"
                data-active={menuItem.active === NavBarActiveKey.CBT}
              >
                {menuItem.label}
              </Link>
            ))}
            <Link href={`https:${LINKAREER_DOMAIN}/nav`} className="expand-menu-item">
              더보기 +
            </Link>
          </div>
        </div>
      )}
      {secondDepthMenuDataList && (
        <div className="second-depth-nav">
          {secondDepthMenuDataList.menuItems?.map((menuItem) => (
            <Link
              key={menuItem.label}
              href={menuItem.href}
              className={`second-depth-item  ${
                menuItem.badge === NavBarBadge.NEW ? 'new-badge' : ''
              }`}
              data-active={menuItem.active === activeCategory}
            >
              {menuItem.label}
            </Link>
          ))}
        </div>
      )}
    </StyledWrapper>
  );
};

export default NavBarMobile;

const StyledWrapper = styled.div`
  background-color: #ffffff;
  position: relative;

  .menu-list {
    display: flex;
    gap: 18px;
    border-bottom: 1px solid #e8e8e8;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      background-color: transparent;
    }

    .menu-item {
      height: 50px;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: -0.64px;
      padding: 0px;

      &:nth-last-of-type(1) {
        &::after {
          content: '';
          width: 50px;
          height: 100%;
          display: block;
        }

        &:active,
        &:hover {
          &::after {
            position: relative;
            z-index: 1;
            background-color: #ffffff;
          }
        }
      }

      .active-line {
        display: none;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #01a0ff;
      }

      &[data-active='true'] {
        background-color: #fff;
        color: #01a0ff;

        .active-line {
          display: block;
        }
      }

      &[data-badge='true'] {
        margin-right: 10px;
      }
    }

    > .gradient-box {
      position: absolute;
      right: 0px;
      width: 50px;
      height: 50px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 22%);
      z-index: 2;
    }

    .expand-more-btn {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
      z-index: 3;

      svg {
        width: 10px;
      }
    }
  }

  .expand-wrapper {
    position: absolute;
    top: 51px;
    z-index: 30;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(10, 10, 10, 0.86);

    .expand-menu-list {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 20px;
      row-gap: 8px;
      column-gap: 5px;
      background-color: #ffffff;

      .expand-menu-item {
        font-size: 13px;
        font-weight: normal;
        line-height: 1.85;
        letter-spacing: -0.52px;
        color: #333;
        padding: 7px 15px;
        background-color: #fff;
        border: 1px solid #dddddd;
        border-radius: 20px;

        &[data-active='true'] {
          color: #ffffff;
          background-color: #01a0ff;
          border: none;
        }
      }
    }
  }

  .second-depth-nav {
    height: 44px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #e8e8e8;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    .second-depth-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: -0.64px;
      flex-shrink: 0;
      position: relative;

      &.new-badge::after {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #ef2929;
        box-sizing: border-box;
        border-radius: 6px;
        position: absolute;
        top: 0;
        right: -8px;
      }

      &[data-active='true'] {
        color: #01a0ff;
        font-weight: bold;
      }

      .new-badge {
        top: -3px;
        right: -9px;
      }
    }
  }
`;
