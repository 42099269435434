import { ProjectConfig } from 'app.core/common/module/projectConfigs';
import HeaderLinkareer from 'app.core/linkareer/component/Header/HeaderLinkareer';
import FooterLinkareer from 'app.core/linkareer/component/Footer/FooterLinkareer';
import { gqlEmbeddablePostsQuery, gqlJoinChatRoom } from 'app.core/linkareer/gql/linkareer.graphql';
import { LinkareerSeoConfig } from 'app.core/linkareer/component/seo/SEOLinkareer';
import {
  CommunityAddBookmarkMutationResult,
  CommunityAddBookmarkMutationVariables,
  CommunityBoardBySlugResult,
  CommunityBoardBySlugVariables,
  EmbeddablePostsResult,
  EmbeddablePostsVariables,
} from 'app.core/linkareer/types/gql.types';
import {
  linkareerCommunityGqlRequest,
  linkareerGqlRequest,
} from 'app.core/linkareer/module/linkareerGqlRequest';
import {
  gqlAddBookmark,
  gqlBoardBySlug,
  gqlCbtExamChatRoom,
} from 'app.core/linkareer/gql/linkareer.graphql';
import ExamApi from 'lib/api/exam';

const linkareerSeo = new LinkareerSeoConfig();

export const LinkareerHosts = ['cbt.linkareer.com', 'cbt.lnkr.cc', 'local-cbt.lnkr.cc'];

export const LinkareerProject: { [key in 'production' | 'development' | 'test']: ProjectConfig } = {
  development: {
    projectName: 'Linkareer',
    projectNameKR: '링커리어',
    clientBaseUrl: 'https://lnkr.cc/',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://lnkr.cc/login?next=${encodeURIComponent(url)}`;
      return `https://lnkr.cc/login`;
    },
    logoutRedirectUri: 'https://gateway-api.lnkr.cc/logout',
    apiBaseUrl: 'https://gateway-api.lnkr.cc',
    apiBaseProxyUrl: 'https://api.lnkr.cc/academy',
    communityUrl: 'https://cbt-community.lnkr.cc',
    cbtBaseUrl: 'https://cbt.lnkr.cc',
    idp: 'linkareer',
    logo: '/images/logo/linkareer/logo.svg',
    logoImagePng: '/images/logo/linkareer/logo(800X800).png',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee', // 로컬 환경에서는 카카오 developer를 통한 공유하기가 잘못된 요청 팝업으로 동작됩니다
    googleTagManagerId: 'G-D5TG9CDEF8',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    defaultSeo: () => linkareerSeo.defaultSeo() || {},
    examListSeo: (type) => linkareerSeo.examListSeo(type),
    examDetailSeo: (data) => linkareerSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => linkareerSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => linkareerSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => linkareerSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => linkareerSeo.examResultSeo(data),
    examSolutionSeo: (data) => linkareerSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => linkareerSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => linkareerSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => linkareerSeo.myCBTNoteSeo(),
    getEmbedablePosts: async (uri: string, page: number, pageSize: number) => {
      try {
        const response = await linkareerCommunityGqlRequest<
          EmbeddablePostsResult,
          EmbeddablePostsVariables
        >({
          query: gqlEmbeddablePostsQuery,
          variables: { uri, page, pageSize },
        });

        if (!response?.embeddablePosts) {
          throw new Error('임베드 게시글을 불러오는데 실패했습니다.');
        }

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    handleAnswerButtonClick: async (examIdx: number) => {
      try {
        const enterChatRoom = async () => {
          //   1 채팅방 ID를 링커리어 백엔드에서 가져오기
          const response = await linkareerGqlRequest<
            { cbtExamChatRoom: { chatRoomId: string } },
            { cbtExamId: string }
          >({
            query: gqlCbtExamChatRoom,
            variables: { cbtExamId: examIdx.toString() },
          });

          const chatRoomId = response?.cbtExamChatRoom.toString();

          if (!chatRoomId) {
            throw new Error('채팅방 ID를 찾을 수 없습니다.');
          }

          //  2. 채팅방 입장 query
          const joinChatRoomResponse = await linkareerGqlRequest<
            { joinChatRoom: { user: { id: string; chatNickname: string; chatIsBanned: boolean } } },
            { chatRoomId: string }
          >({
            query: gqlJoinChatRoom,
            variables: { chatRoomId },
          });
        };

        // 3. 시험 정보방 내 관련 시험 게시판 즐겨찾기 추가
        const addBoardBookmark = async () => {
          // 시험 id로 시험 조회하여 게시판 slug 조회
          const exam = await ExamApi.getExamInfo(examIdx);
          const boardSlug = exam?.communityBoardId;

          if (!boardSlug) {
            throw new Error('게시판 slug를 찾을 수 없습니다.');
          }

          // 게시판 slug로 링커리어 게시판 조회하여 게시판 id 조회
          const boardSlugResponse = await linkareerCommunityGqlRequest<
            CommunityBoardBySlugResult,
            CommunityBoardBySlugVariables
          >({
            query: gqlBoardBySlug,
            variables: { boardSlug },
          });

          const boardId = boardSlugResponse?.boardBySlug?.menu.id;

          if (!boardId) {
            throw new Error('게시판 ID를 찾을 수 없습니다.');
          }

          // 게시판 id로 링커리어 백엔드 addBookmark 뮤테이션 호출하여 즐겨찾기 추가
          const addBookmarkResponse = await linkareerCommunityGqlRequest<
            CommunityAddBookmarkMutationResult,
            CommunityAddBookmarkMutationVariables
          >({
            query: gqlAddBookmark,
            variables: { menuID: boardId },
          });
        };

        await Promise.all([enterChatRoom(), addBoardBookmark()]);
      } catch (error) {
        console.error(error);
      }
    },
    handleLikeButtonClick: async (examIdx: number) => {
      try {
        // 1. 채팅방 ID를 링커리어 백엔드에서 가져오기
        const response = await linkareerGqlRequest<
          { cbtExamChatRoom: { chatRoomId: string } },
          { cbtExamId: string }
        >({
          query: gqlCbtExamChatRoom,
          variables: { cbtExamId: examIdx.toString() },
        });

        const chatRoomId = response?.cbtExamChatRoom.toString();

        if (!chatRoomId) {
          throw new Error('채팅방 ID를 찾을 수 없습니다.');
        }

        //  2. 채팅방 입장 query
        const joinChatRoomResponse = await linkareerGqlRequest<
          { joinChatRoom: { user: { id: string; chatNickname: string; chatIsBanned: boolean } } },
          { chatRoomId: string }
        >({
          query: gqlJoinChatRoom,
          variables: { chatRoomId },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  production: {
    projectName: 'Linkareer',
    projectNameKR: '링커리어',
    clientBaseUrl: 'https://linkareer.com/',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://linkareer.com/login?next=${encodeURIComponent(url)}`;
      return 'https://linkareer.com/login';
    },
    logoutRedirectUri: 'https://api.linkareer.com/logout',
    apiBaseUrl: 'https://api.linkareer.com',
    apiBaseProxyUrl: 'https://api.linkareer.com/academy',
    communityUrl: 'https://cbt-community.linkareer.com',
    cbtBaseUrl: 'https://cbt.linkareer.com',
    idp: 'linkareer',
    logo: '/images/logo/linkareer/logo.svg',
    logoImagePng: '/images/logo/linkareer/logo(800X800).png',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee',
    googleTagManagerId: 'G-D5TG9CDEF8',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    defaultSeo: () => linkareerSeo.defaultSeo() || {},
    examListSeo: (type) => linkareerSeo.examListSeo(type),
    examDetailSeo: (data) => linkareerSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => linkareerSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => linkareerSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => linkareerSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => linkareerSeo.examResultSeo(data),
    examSolutionSeo: (data) => linkareerSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => linkareerSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => linkareerSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => linkareerSeo.myCBTNoteSeo(),
    getEmbedablePosts: async (uri: string, page: number, pageSize: number) => {
      try {
        const response = await linkareerCommunityGqlRequest<
          EmbeddablePostsResult,
          EmbeddablePostsVariables
        >({
          query: gqlEmbeddablePostsQuery,
          variables: { uri, page, pageSize },
        });

        if (!response?.embeddablePosts) {
          throw new Error('임베드 게시글을 불러오는데 실패했습니다.');
        }

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    handleAnswerButtonClick: async (examIdx: number) => {
      try {
        const enterChatRoom = async () => {
          //   1 채팅방 ID를 링커리어 백엔드에서 가져오기
          const response = await linkareerGqlRequest<
            { cbtExamChatRoom: { chatRoomId: string } },
            { cbtExamId: string }
          >({
            query: gqlCbtExamChatRoom,
            variables: { cbtExamId: examIdx.toString() },
          });

          const chatRoomId = response.cbtExamChatRoom.toString();

          if (!chatRoomId) {
            throw new Error('채팅방 ID를 찾을 수 없습니다.');
          }

          //  2. 채팅방 입장 query
          const joinChatRoomResponse = await linkareerGqlRequest<
            { joinChatRoom: { user: { id: string; chatNickname: string; chatIsBanned: boolean } } },
            { chatRoomId: string }
          >({
            query: gqlJoinChatRoom,
            variables: { chatRoomId },
          });
        };

        // 3. 시험 정보방 내 관련 시험 게시판 즐겨찾기 추가
        const addBoardBookmark = async () => {
          // 시험 id로 시험 조회하여 게시판 slug 조회
          const exam = await ExamApi.getExamInfo(examIdx);
          const boardSlug = exam?.communityBoardId;

          if (!boardSlug) {
            throw new Error('게시판 slug를 찾을 수 없습니다.');
          }

          // 게시판 slug로 링커리어 게시판 조회하여 게시판 id 조회
          const boardSlugResponse = await linkareerCommunityGqlRequest<
            CommunityBoardBySlugResult,
            CommunityBoardBySlugVariables
          >({
            query: gqlBoardBySlug,
            variables: { boardSlug },
          });

          const boardId = boardSlugResponse?.boardBySlug?.menu.id;

          if (!boardId) {
            throw new Error('게시판 ID를 찾을 수 없습니다.');
          }

          // 게시판 id로 링커리어 백엔드 addBookmark 뮤테이션 호출하여 즐겨찾기 추가
          const addBookmarkResponse = await linkareerCommunityGqlRequest<
            CommunityAddBookmarkMutationResult,
            CommunityAddBookmarkMutationVariables
          >({
            query: gqlAddBookmark,
            variables: { menuID: boardId },
          });
        };

        await Promise.all([enterChatRoom(), addBoardBookmark()]);
      } catch (error) {
        console.error(error);
      }
    },
    handleLikeButtonClick: async (examIdx: number) => {
      try {
        // 1. 채팅방 ID를 링커리어 백엔드에서 가져오기
        const response = await linkareerGqlRequest<
          { cbtExamChatRoom: { chatRoomId: string } },
          { cbtExamId: string }
        >({
          query: gqlCbtExamChatRoom,
          variables: { cbtExamId: examIdx.toString() },
        });

        const chatRoomId = response?.cbtExamChatRoom.toString();

        if (!chatRoomId) {
          throw new Error('채팅방 ID를 찾을 수 없습니다.');
        }

        //  2. 채팅방 입장 query
        const joinChatRoomResponse = await linkareerGqlRequest<
          { joinChatRoom: { user: { id: string; chatNickname: string; chatIsBanned: boolean } } },
          { chatRoomId: string }
        >({
          query: gqlJoinChatRoom,
          variables: { chatRoomId },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  test: {
    projectName: 'Linkareer',
    projectNameKR: '링커리어',
    clientBaseUrl: 'https://lnkr.cc/',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://lnkr.cc/login?next=${encodeURIComponent(url)}`;
      return `https://lnkr.cc/login`;
    },
    logoutRedirectUri: 'https://gateway-api.lnkr.cc/logout',
    apiBaseUrl: 'https://gateway-api.lnkr.cc',
    apiBaseProxyUrl: 'https://api.lnkr.cc/academy',
    communityUrl: 'https://cbt-community.lnkr.cc',
    cbtBaseUrl: 'https://cbt.lnkr.cc',
    idp: 'linkareer',
    logo: '/images/logo/linkareer/logo.svg',
    logoImagePng: '/images/logo/linkareer/logo(800X800).png',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee',
    googleTagManagerId: 'G-D5TG9CDEF8',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    defaultSeo: () => linkareerSeo.defaultSeo() || {},
    examListSeo: (type) => linkareerSeo.examListSeo(type),
    examDetailSeo: (data) => linkareerSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => linkareerSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => linkareerSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => linkareerSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => linkareerSeo.examResultSeo(data),
    examSolutionSeo: (data) => linkareerSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => linkareerSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => linkareerSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => linkareerSeo.myCBTNoteSeo(),
    getEmbedablePosts: async (uri: string, page: number, pageSize: number) => {
      try {
        const response = await linkareerCommunityGqlRequest<
          EmbeddablePostsResult,
          EmbeddablePostsVariables
        >({
          query: gqlEmbeddablePostsQuery,
          variables: { uri, page, pageSize },
        });

        if (!response?.embeddablePosts) {
          throw new Error('임베드 게시글을 불러오는데 실패했습니다.');
        }

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    handleAnswerButtonClick: async (examIdx: number) => {
      try {
        const enterChatRoom = async () => {
          //   1 채팅방 ID를 링커리어 백엔드에서 가져오기
          const response = await linkareerGqlRequest<
            { cbtExamChatRoom: { chatRoomId: string } },
            { cbtExamId: string }
          >({
            query: gqlCbtExamChatRoom,
            variables: { cbtExamId: examIdx.toString() },
          });

          const chatRoomId = response.cbtExamChatRoom.toString();

          if (!chatRoomId) {
            throw new Error('채팅방 ID를 찾을 수 없습니다.');
          }

          //  2. 채팅방 입장 query
          const joinChatRoomResponse = await linkareerGqlRequest<
            { joinChatRoom: { user: { id: string; chatNickname: string; chatIsBanned: boolean } } },
            { chatRoomId: string }
          >({
            query: gqlJoinChatRoom,
            variables: { chatRoomId },
          });
        };

        // 3. 시험 정보방 내 관련 시험 게시판 즐겨찾기 추가
        const addBoardBookmark = async () => {
          // 시험 id로 시험 조회하여 게시판 slug 조회
          const exam = await ExamApi.getExamInfo(examIdx);
          const boardSlug = exam?.communityBoardId;

          if (!boardSlug) {
            throw new Error('게시판 slug를 찾을 수 없습니다.');
          }

          // 게시판 slug로 링커리어 게시판 조회하여 게시판 id 조회
          const boardSlugResponse = await linkareerCommunityGqlRequest<
            CommunityBoardBySlugResult,
            CommunityBoardBySlugVariables
          >({
            query: gqlBoardBySlug,
            variables: { boardSlug },
          });

          const boardId = boardSlugResponse?.boardBySlug?.menu.id;

          if (!boardId) {
            throw new Error('게시판 ID를 찾을 수 없습니다.');
          }

          // 게시판 id로 링커리어 백엔드 addBookmark 뮤테이션 호출하여 즐겨찾기 추가
          const addBookmarkResponse = await linkareerCommunityGqlRequest<
            CommunityAddBookmarkMutationResult,
            CommunityAddBookmarkMutationVariables
          >({
            query: gqlAddBookmark,
            variables: { menuID: boardId },
          });
        };

        await Promise.all([enterChatRoom(), addBoardBookmark()]);
      } catch (error) {
        console.error(error);
      }
    },
    handleLikeButtonClick: async (examIdx: number) => {
      try {
        // 1. 채팅방 ID를 링커리어 백엔드에서 가져오기
        const response = await linkareerGqlRequest<
          { cbtExamChatRoom: { chatRoomId: string } },
          { cbtExamId: string }
        >({
          query: gqlCbtExamChatRoom,
          variables: { cbtExamId: examIdx.toString() },
        });

        const chatRoomId = response?.cbtExamChatRoom.toString();

        if (!chatRoomId) {
          throw new Error('채팅방 ID를 찾을 수 없습니다.');
        }

        //  2. 채팅방 입장 query
        const joinChatRoomResponse = await linkareerGqlRequest<
          { joinChatRoom: { user: { id: string; chatNickname: string; chatIsBanned: boolean } } },
          { chatRoomId: string }
        >({
          query: gqlJoinChatRoom,
          variables: { chatRoomId },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
