import {
  CBT_COMMUNITY_DOMAIN,
  LINKAREER_CBT_DOMAIN,
  LINKAREER_COMMUNITY_DOMAIN,
  LINKAREER_DOMAIN,
} from 'src/app.core/linkareer/constant/urls';
import { UrlObject } from 'url';

export enum NavBarActiveKey {
  CHANNEL,
  CHANNEL_INTEREST,
  RECRUIT_HOME,
  COMMUNITY,
  INTERN,
  RECRUIT,
  ACTIVITY,
  CONTEST,
  EDUCATION,
  CLUB,
  CALENDAR,
  COVER_LETTER,
  SELF_INTRODUCTION,
  CBT,
  CBT_ARCHIVE,
  CBT_REVIEW,
  CBT_NOTE,
  CBT_COMMUNITY,
  CAREER_MANAGEMENT,
  GSAT,
  COMPANY_ANALYSIS,
}

export enum NavBarBadge {
  NONE,
  NEW,
  CBT,
}

export interface NavMenuItemType {
  label: string;
  href: UrlObject;
  badge?: NavBarBadge;
  active: NavBarActiveKey;
}

export interface HeaderNavItemType extends NavMenuItemType {
  menuItems?: Array<NavMenuItemType>;
}

// URL 생성 함수
const createUrl = (domain: string, path: string, query?: Record<string, string>): UrlObject => {
  return { pathname: `https://${domain}${path}`, query };
};

// 메뉴 아이템 생성 함수
const createMenuItem = (
  label: string,
  active: NavBarActiveKey,
  href: UrlObject,
  badge?: NavBarBadge
): NavMenuItemType => {
  return {
    label,
    href,
    active,
    badge: badge ?? NavBarBadge.NONE,
  };
};

const createSubMenuItem = (
  label: string,
  active: NavBarActiveKey,
  path: string,
  query?: Record<string, string>,
  badge?: NavBarBadge
) => {
  const menuItem = createMenuItem(label, active, createUrl(LINKAREER_DOMAIN, path, query), badge);

  return menuItem;
};

// 데스크탑 메뉴
export const NAV_BAR_MENU_ITEMS: Array<HeaderNavItemType> = [
  {
    label: '신입/인턴',
    href: createUrl(LINKAREER_DOMAIN, '/recruit-home'),
    active: NavBarActiveKey.RECRUIT_HOME,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.RECRUIT_HOME, '/recruit-home'),
      createSubMenuItem('인턴', NavBarActiveKey.INTERN, '/list/intern'),
      createSubMenuItem('신입', NavBarActiveKey.RECRUIT, '/list/recruit'),
      createSubMenuItem(
        '기업분석자료',
        NavBarActiveKey.COMPANY_ANALYSIS,
        '/company-analysis',
        undefined,
        NavBarBadge.NEW
      ),
      createSubMenuItem('공고달력', NavBarActiveKey.CALENDAR, '/calendar', {
        parentActiveTab: 'recurit',
      }),
      createSubMenuItem('GSAT 합격예측', NavBarActiveKey.GSAT, '/prediction/gsat2025-1'),
    ],
  },
  {
    label: '대외활동/교육/공모전',
    active: NavBarActiveKey.ACTIVITY,
    href: createUrl(LINKAREER_DOMAIN, '/list/activity'),
    menuItems: [
      createSubMenuItem('대외활동', NavBarActiveKey.ACTIVITY, '/list/activity'),
      createSubMenuItem(
        '교육',
        NavBarActiveKey.EDUCATION,
        '/list/education',
        undefined,
        NavBarBadge.NEW
      ),
      createSubMenuItem('공모전', NavBarActiveKey.CONTEST, '/list/contest'),
      createSubMenuItem('동아리', NavBarActiveKey.CLUB, '/list/club'),
      createSubMenuItem('공고달력', NavBarActiveKey.CALENDAR, '/calendar', {
        parentActiveTab: 'activity',
      }),
    ],
  },
  {
    label: 'CBT',
    active: NavBarActiveKey.CBT,
    badge: NavBarBadge.CBT,
    href: { pathname: LINKAREER_CBT_DOMAIN },
    menuItems: [
      createMenuItem('시험 CBT', NavBarActiveKey.CBT, {
        pathname: LINKAREER_CBT_DOMAIN,
      }),
      createMenuItem(
        '시험 정보방',
        NavBarActiveKey.CBT_COMMUNITY,
        {
          pathname: `${CBT_COMMUNITY_DOMAIN}/cbt-community`,
        },
        NavBarBadge.NEW
      ),
      createMenuItem('나의 관심 시험', NavBarActiveKey.CBT_ARCHIVE, {
        pathname: `${LINKAREER_CBT_DOMAIN}/mycbt/archive`,
      }),
      createMenuItem('CBT 결과보기', NavBarActiveKey.CBT_REVIEW, {
        pathname: `${LINKAREER_CBT_DOMAIN}/mycbt/review`,
      }),
      createMenuItem('오답노트', NavBarActiveKey.CBT_NOTE, {
        pathname: `${LINKAREER_CBT_DOMAIN}/mycbt/note`,
      }),
    ],
  },
  {
    label: '커뮤니티',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community'),
    menuItems: [
      createMenuItem(
        '커뮤니티',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community')
      ),
      createMenuItem(
        '시험 정보방',
        NavBarActiveKey.CBT_COMMUNITY,
        {
          pathname: `${CBT_COMMUNITY_DOMAIN}/cbt-community`,
        },
        NavBarBadge.NEW
      ),
    ],
  },
  {
    label: '자기소개서',
    href: createUrl(LINKAREER_DOMAIN, '/cover-letter/search'),
    active: NavBarActiveKey.COVER_LETTER,
    menuItems: [
      createSubMenuItem('자소서 만능 검색기', NavBarActiveKey.COVER_LETTER, '/cover-letter/search'),
      createSubMenuItem(
        '자기소개서 작성',
        NavBarActiveKey.SELF_INTRODUCTION,
        '/self-introduction/manage'
      ),
      createSubMenuItem(
        '기업분석자료',
        NavBarActiveKey.COMPANY_ANALYSIS,
        '/company-analysis',
        undefined,
        NavBarBadge.NEW
      ),
    ],
  },
  {
    label: '멘토 게시판',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring'),
    badge: NavBarBadge.NEW,
    menuItems: [
      createMenuItem(
        '취업/문과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring')
      ),
      createMenuItem(
        '취업/이과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/STEM_mentoring')
      ),
      createMenuItem(
        '인턴 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentor_intern')
      ),
      createMenuItem(
        '삼성 멘토질문방',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mento_samsung')
      ),
    ],
  },
  {
    label: '채널',
    href: createUrl(LINKAREER_DOMAIN, '/channel', { tab: 'home' }),
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.CHANNEL, '/channel', { tab: 'home' }),
      createSubMenuItem('내 관심 소식', NavBarActiveKey.CHANNEL_INTEREST, '/channel', {
        tab: 'interest',
      }),
    ],
  },
];

// 모바일 메뉴
export const MOBILE_MENU_ITEMS: Array<HeaderNavItemType> = [
  {
    label: '신입/인턴',
    href: createUrl(LINKAREER_DOMAIN, '/recruit-home'),
    active: NavBarActiveKey.RECRUIT_HOME,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.RECRUIT_HOME, '/recruit-home'),
      createSubMenuItem('인턴', NavBarActiveKey.INTERN, '/list/intern'),
      createSubMenuItem('신입', NavBarActiveKey.RECRUIT, '/list/recruit'),
      createSubMenuItem('공고달력', NavBarActiveKey.CALENDAR, '/calendar'),
    ],
  },
  {
    label: '대외활동/교육/공모전',
    active: NavBarActiveKey.ACTIVITY,
    href: createUrl(LINKAREER_DOMAIN, '/list/activity'),
    menuItems: [
      createSubMenuItem('대외활동', NavBarActiveKey.ACTIVITY, '/list/activity'),
      createSubMenuItem('교육', NavBarActiveKey.EDUCATION, '/list/education'),
      createSubMenuItem('공모전', NavBarActiveKey.CONTEST, '/list/contest'),
      createSubMenuItem('동아리', NavBarActiveKey.CLUB, '/list/club'),
    ],
  },
  {
    label: 'CBT',
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.CBT,
    href: { pathname: '/' },
    menuItems: [
      createMenuItem('시험 CBT', NavBarActiveKey.CBT, {
        pathname: '/',
      }),
      createMenuItem(
        '시험 정보방',
        NavBarActiveKey.CBT_COMMUNITY,
        { pathname: `${CBT_COMMUNITY_DOMAIN}/cbt-community` },
        NavBarBadge.NEW
      ),
      createMenuItem('나의 관심 시험', NavBarActiveKey.CBT_ARCHIVE, {
        pathname: '/mycbt/archive',
      }),
      createMenuItem('CBT 결과보기', NavBarActiveKey.CBT_REVIEW, {
        pathname: '/mycbt/review',
      }),
      createMenuItem('오답노트', NavBarActiveKey.CBT_NOTE, {
        pathname: '/mycbt/note',
      }),
    ],
  },
  {
    label: '채널',
    href: createUrl(LINKAREER_DOMAIN, '/channel', { tab: 'home' }),
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.CHANNEL, '/channel', { tab: 'home' }),
      createSubMenuItem('내 관심 소식', NavBarActiveKey.CHANNEL_INTEREST, '/channel', {
        tab: 'interest',
      }),
    ],
  },
  {
    label: '커뮤니티',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community'),
    menuItems: [
      createMenuItem(
        '홈',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community')
      ),
    ],
  },
  {
    label: '멘토 게시판',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring'),
    badge: NavBarBadge.NEW,
    menuItems: [
      createMenuItem(
        '취업/문과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring')
      ),
      createMenuItem(
        '취업/이과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/STEM_mentoring')
      ),
      createMenuItem(
        '인턴 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentor_intern')
      ),
      createMenuItem(
        '삼성 멘토질문방',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mento_samsung')
      ),
    ],
  },
  {
    label: '자기소개서',
    href: createUrl(LINKAREER_DOMAIN, '/cover-letter/search'),
    active: NavBarActiveKey.COVER_LETTER,
    menuItems: [
      createSubMenuItem('자소서 만능 검색기', NavBarActiveKey.COVER_LETTER, '/cover-letter/search'),
      createSubMenuItem(
        '자기소개서 작성',
        NavBarActiveKey.SELF_INTRODUCTION,
        '/self-introduction/manage'
      ),
    ],
  },
  {
    label: '스펙 정리하기',
    href: createUrl(LINKAREER_DOMAIN, '/my-career/spec-management'),
    active: NavBarActiveKey.CAREER_MANAGEMENT,
  },
  {
    label: 'GSAT 합격예측',
    active: NavBarActiveKey.GSAT,
    href: createUrl(LINKAREER_DOMAIN, '/prediction/gsat2025-1'),
  },
];
