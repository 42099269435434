/* eslint-disable class-methods-use-this */
import React, { ErrorInfo } from 'react';
import { Router, withRouter } from 'next/router';
import SentryCaptureBoundaryError from 'utils/error/BoundaryError';

interface RenderFallbackProps<ErrorType extends Error = Error> {
  error: ErrorType;
  reset: () => void;
}

type RenderFallback = <ErrorType extends Error>(
  props: RenderFallbackProps<ErrorType>
) => React.ReactNode;

interface Props {
  children: React.ReactNode;
  errorFallback: RenderFallback;
  router: Router;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

const initialState = { hasError: false, error: null };

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    SentryCaptureBoundaryError(error, errorInfo);
    console.error('Uncaught error:', error, errorInfo);
  }

  resetErrorBoundary = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    this.props.router.events.on('routeChangeComplete', this.resetErrorBoundary);
  }

  componentWillUnmount() {
    this.props.router.events.off('routeChangeComplete', this.resetErrorBoundary);
  }

  render() {
    const { children, errorFallback } = this.props;
    const { hasError } = this.state;
    const error = this.state.error as Error;

    if (hasError) {
      return errorFallback({
        error,
        reset: this.resetErrorBoundary,
      });
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
