// react-query, axios를 이용한 gql query, mutation을 관리합니다

/**
 * @description linkareer 사용자 조회 query
 */
export const gqlCurrentUserQuery = `
  query CurrentUser($notificationType: NotificationScope) {
    currentUser {
      id
      username
      type
      scrapCount
      notificationUnreadCount(notificationType: $notificationType)
      channel {
        id
        url
        deactivatedAt
      }
      tokenSet {
        accessToken
      }
    }
  }
`;

/**
 * @description linkareer 고객 문의 mutation
 */
export const gqlCreateSuggestionMutation = `
  mutation CreateSuggestion($input: createSuggestionInput!) {
    createSuggestion(input: $input) {
      suggestion {
        email
        content
      }
    }
  }
`;

/**
 * @description linkareer 첨부파일 prepare 업로드 mutation
 */
export const gqlAttachmentPrepareUpload = `
  mutation AttachmentPrepareUpload(
    $name: String!
    $size: Int!
    $type: String!
    $path: String
  ) {
    attachmentPrepareUpload(
      name: $name
      size: $size
      type: $type
      path: $path
    ) {
      attachment {
        id
        url
      }
      signedURL
    }
  }
`;

/**
 * @description linkareer 첨부파일 complete 업로드 mutation
 */
export const gqlAttachmentCompleteUpload = `
  mutation AttachmentCompleteUpload($attachmentID: ID!) {
    attachmentCompleteUpload(attachmentID: $attachmentID) {
      attachment {
        id
        url
        name
        type
        size
      }
    }
  }
`;

/**
 * @description linkareer 자소서 건수 조회 쿼리
 */
const gqlCoverLetterCountQuery = `
  query CoverLetterTotalCount {
    coverLetterTotalCount
  }
`;

/**
 * @description 관심시험추가/cbt 선지 클릭 시 채팅ID 조회 쿼리
 */
export const gqlCbtExamChatRoom = `
    query CbtExamChatRoom($cbtExamId: ID!) {
        cbtExamChatRoom(cbtExamId: $cbtExamId)
    }
`;

/**
 * @description cbt 선지 클릭 시 게시판ID 조회 쿼리
 */
export const gqlBoardBySlug = `
  query BoardBySlug($boardSlug: String!) {
    boardBySlug(boardSlug: $boardSlug) {
      id
      slug
      menu {
      id
      }
    }
  }
`;

/**
 * @description cbt 선지 클릭 시 addBookmark mutation
 */
// export const gqlAddBookmark = `
//   mutation AddBookmark($newsItemId: ID!) {
//     addBookmark(newsItemId: $newsItemId) {
//       newsItem {
//         id
//         }
//       }
//     }
// `;

export const gqlAddBookmark = `
mutation gqlAddBookmark($menuID: ID!) {
  addBookmark(id: $menuID) {
    menu {
 id
    title
    slug
    hasNewPost
    isShortcut
    bookmarkable
    viewerHasBookmarked
    }
  }
}
`;

/**
 * @description linkareer 임베드 게시판 조회 쿼리
 */
export const gqlEmbeddablePostsQuery = `
query EmbeddablePosts($uri: String!, $page: Int!, $pageSize: Int!) {
  embeddablePosts(
    filterBy: { uri: $uri }
    pagination: { page: $page, pageSize: $pageSize }
  ) {
    totalCount
    nodes {
      id
      title
      createdAt
      contentText
      nickname
      views
      commentCount
      containsImage
      containsYoutube
      board {
        id
        title
        slug
      }
    }
  }
}
`;

/**
 * @description linkareer 채팅 입장 mutation
 */
export const gqlJoinChatRoom = `
  mutation gqlJoinChatRoom($chatRoomId: ID!) {
    joinChatRoom(chatRoomId: $chatRoomId) {
      chatRoom {
        id
      }
      user {
        id
      }
    }
  }
`;
