import styles from './styles.module.scss';
import { getProjectConfigByHost } from 'src/app.core/common/module/getProjectConfigByHost';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { isAxiosError } from 'axios';

interface Props<ErrorType extends Error = Error> {
  error: ErrorType;
  reset: () => void;
  host: string;
}

export default function ErrorHelper({ error, reset, host }: Props) {
  const projectConfig = getProjectConfigByHost(host);
  const { logo, projectNameKR, clientBaseUrl } = projectConfig;
  const router = useRouter();

  const isUnauthorized =
    isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403);

  return (
    <div className={styles.errorBoundaryRoot}>
      {logo && (
        <div className={styles.logo}>
          <Link href={clientBaseUrl}>
            <img src={logo} alt={projectNameKR} />
          </Link>
        </div>
      )}
      {isUnauthorized ? (
        <>
          <div className={styles.title}>접근 권한이 없는 페이지입니다.</div>
          <div className={styles.content}>
            <div>반복해서 이런 문제가 발생한다면,</div>
            <div>고객 문의를 통해 문의사항을 남겨주세요.</div>
          </div>
          <button
            onClick={() => {
              reset();
              router.replace('/');
            }}
            className={styles.refreshBtn}
          >
            메인 페이지로 이동
          </button>
        </>
      ) : (
        <>
          <div className={styles.title}>오류가 발생하였습니다</div>
          <div className={styles.content}>
            <div>페이지 새로고침으로 해결되지 않는다면,</div>
            <div>고객 문의를 통해 문의사항을 남겨주세요.</div>
          </div>
          <button onClick={reset} className={styles.refreshBtn}>
            새로고침
          </button>
        </>
      )}
    </div>
  );
}
