/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/inline-script-id */ /* eslint-disable camelcase */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable camelcase */

import {
  dehydrate,
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App, { AppContext, AppProps } from 'next/app';
import { Suspense, useEffect, useState } from 'react';
import { RecoilEnv, RecoilRoot } from 'recoil';

import useDehydratedRecoilState from 'app.hooks/useDehydratedRecoilState';
import AppWeb from 'app.layout/AppWeb';
import { theme } from 'app.styled/theme';
import { ThemeProvider } from 'styled-components';
import { REACT_QUERY_CONFIG } from 'utils/config';
import ErrorHelper from 'utils/error/ErrorHelper';
import ErrorBoundary from 'utils/etc/Boundary/ErrorBoundary';
import LoadingBoundary from 'utils/etc/Boundary/LoadingBoundary';

import { globalContext } from 'lib/api/axios/context';
import UserApi from 'lib/api/users';
import 'react-quill/dist/quill.snow.css';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import '../styles/globals.css';
import 'react-quill/dist/quill.snow.css';
import ModalRoot from 'src/app.component/LEGACY__molecules/Common/Modal/ModalRoot';
import Toast from 'src/app.component/LEGACY__atoms/Common/Toast';
import { getProjectConfigByHost } from 'app.core/common/module/getProjectConfigByHost';
import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import { useRouter } from 'next/router';

/// ////////////////////////////////////////////////////////////////////////
// Recoil Key 중복되는 버그 삭제
// RECOIL ERROR FIX //
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
// RECOIL ERROR FIX //
/// ////////////////////////////////////////////////////////////////////////

export type PageProps = {
  host: string;
  dehydratedState: any;
  seoData: DefaultSeoProps;
  isWebView: boolean;
};

export interface CbtAppProps extends AppProps {
  dehydratedState?: DehydratedState;
  pageProps: PageProps;
}

function CbtApp({ Component, pageProps, dehydratedState }: CbtAppProps) {
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient(REACT_QUERY_CONFIG));
  const dehydratedRecoilState = useDehydratedRecoilState(pageProps);

  const isWebView = pageProps.isWebView;

  useEffect(() => {
    if (!isWebView) return;

    const killRouteChange = () => {
      // ! Next.js 에서는 route cancelling을 정식 지원하지 않습니다. 해당 Error throwing은 임시방편입니다.
      // * 참고: https://github.com/vercel/next.js/discussions/32231
      router.events.emit('routeChangeError');
      throw 'Prevent unwanted route shift on Webview';
    };

    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        window.location.href = url;
        killRouteChange();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const mergeDehydratedState = {
    mutations: [
      ...(pageProps?.dehydratedState?.mutations || []),
      ...(dehydratedState?.mutations || []),
    ],
    queries: [...(pageProps?.dehydratedState?.queries || []), ...(dehydratedState?.queries || [])],
  };

  return (
    <main>
      <DefaultSeo {...pageProps.seoData} />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ErrorBoundary errorFallback={(props) => <ErrorHelper {...props} host={pageProps?.host} />}>
          <Suspense>
            <LoadingBoundary>
              <Hydrate state={mergeDehydratedState}>
                <RecoilRoot initializeState={dehydratedRecoilState}>
                  <ThemeProvider theme={theme}>
                    <ModalRoot />
                    <Toast />
                    <AppWeb Component={Component} pageProps={pageProps} />
                  </ThemeProvider>
                </RecoilRoot>
              </Hydrate>
            </LoadingBoundary>
          </Suspense>
        </ErrorBoundary>
      </QueryClientProvider>
    </main>
  );
}

export default CbtApp;

CbtApp.getInitialProps = async (ctx: AppContext) => {
  const appProps = await App.getInitialProps(ctx);
  let currentHost = '';
  let isWebView = false;

  //Server Side Case
  if (ctx?.ctx.req) {
    currentHost = ctx?.ctx.req?.headers.host ?? '';
    globalContext.setCtx(ctx);
    isWebView = !!ctx?.ctx.req?.headers.cookie?.includes('disabledHeader');
  }

  //Client Side Case
  if (typeof window !== 'undefined' && window.location?.host) {
    currentHost = window.location?.host;
    isWebView = document.cookie.includes('disabledHeader');
  }

  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(['/users/me'], () => UserApi.currentUser());
  const dehydratedState = dehydrate(queryClient);

  const { defaultSeo } = getProjectConfigByHost(currentHost);

  const seoData = defaultSeo();

  return {
    ...appProps,
    pageProps: {
      host: currentHost,
      isWebView,
      ...appProps.pageProps,
      seoData,
    },
    dehydratedState,
  };
};
